import React from "react"
import "./AreaguideCard.scss"
import { communitiesURL } from "@lib/urls"
import GGFXImage from "@components/elements/GGFXImage"

const AreaguideCard = ({ areaguide }) => {
  if (!areaguide) return null

  const { title, tile_image, slug, ggfx_results, strapi_id } = areaguide
  return (
    <div className="areaguide-card">
      <a href={`${communitiesURL + slug}/`} className="img-section img-zoom" target="_blank">
        {/* <img src={tile_image?.url} alt={title} /> */}
        <GGFXImage
          ImageSrc={tile_image}
          altText={title}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="area-guide.tile_image.tile_img"
          strapiID={strapi_id}
        />
      </a>
      <a href={`${communitiesURL + slug}/`} className="title" target="_blank">
        {title}
      </a>
    </div>
  )
}

export default AreaguideCard
