import BannerBG from "@components/Banner/BannerBG"
import BannerListing from "@components/BannerListing/BannerListing"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import CommunitiesListing from "@components/CommunitiesListing/CommunitiesListing"
import Layout from "@components/Layout/Layout"
import NewsListing from "@components/NewsListing/NewsListing"
import TeamListing from "@components/TeamListing/TeamListing"
import Seo from "@components/seo"
import { graphql } from "gatsby"
import React from "react"
import "../styles/listing-page.scss"
import DevelopersListing from "@components/DevelopersListing/DevelopersListing"

const ListingTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.modules
  const banner = pageData?.banner

  return (
    <Layout pageData={pageData}>
      <div className="listing-page-wrap">
        <div className="listing-page-top">
          <BannerBG />
          <Breadcrumbs menuData={pageData?.menu} />
          <BannerListing bannerData={banner} />
        </div>

        {modules?.map((module) => (
          <React.Fragment key={module.id}>
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "team_listing" && <TeamListing />}
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "news_listing" && <NewsListing />}
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "communities_listing" && <CommunitiesListing />}
            {module?.strapi_component === "modules.listing-module" &&
              module.module === "developer_listing" && <DevelopersListing />}
          </React.Fragment>
        ))}
      </div>
    </Layout>
  )
}

export default ListingTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_LISTING_MODULE {
          id
          module
          strapi_component
        }
      }
    }
  }
`
